<template>
	<header ref="headingImage">
		<slot>
			<hero-image :item="headingImage" />
		</slot>
		<a v-if="route.name && route.name.startsWith('_')" class="scroll-down" @click="scrollDown()">
			<img src="~assets/images/arrow-down-white.png" alt="Scroll" />
		</a>
	</header>
</template>

<script setup>
const route = useRoute();

const scrollDown = () => {
	const scrollHeight = ref.$refs.headingImage.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};

defineProps({
	headingImage: {
		type: Object,
		default: () => {},
	},
});
</script>

<style lang="scss" scoped>
header {
	position: relative;
}

.scroll-down {
	position: absolute;
	bottom: 50px;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	z-index: 4;
	cursor: pointer;

	&:hover img {
		transform: scale(1.1);
	}

	img {
		width: 47px;
		height: 47px;
		transition: all 0.3s ease-in-out;
	}
}
</style>
